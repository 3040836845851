<template>
  <div v-if="hasFilterApplied">
    <!-- <h5 class="box-title mb-0 pb-2">Filtros aplicados</h5> -->
    <filter-item-applied v-for="(item, index) in activeFilters" :key="index" :itemFilterValue="item" :itemFilterKey="index" :noFilterList="noFilterList" />
  </div>
</template>

<script>
import FilterItemApplied from '@/components/FilterItemAppliedProcess'

export default {
  name: 'FilterContainer',
  data () {
    return {
      filtros: [],
      dic: {
        'filProcesso': 'internal_code',
        'filEmployee': 'employee',
        'filEmployees': 'employees',
        'filUsers': 'users',
        'filYear': 'year',
        'filMonth': 'month',
        'filWeek': 'week',
        'filQuarter': 'quarter',
        'filReferenciaCliente': 'customer_reference',
        'filCliente': 'customer',
        'filAgenteOrigem': 'agent_origin',
        'filHBL': 'hbl',
        'filPeriodoEmbarqueDtInicio': 'arrival_begin_date',
        'filPeriodoEmbarqueDtFim': 'arrival_end_date',
        'filPeriodoDesembarqueDtInicio': 'departure_begin_date',
        'filPeriodoDesembarqueDtFim': 'departure_end_date',
        'filPeriodo': 'period',
        'filTipoRequisicaoAPI': 'tipo_requisicao',
        'filTipoRequisicaoPortal': 'tipo_requisicao',
        'filTipoOperacaoModalExportacao': 'operation',
        'filTipoOperacaoModalImportacao': 'operation',
        'filTipoOperacaoModalNacional': 'operation',
        'filTipoOperacaoModalAereo': 'modal',
        'filTipoOperacaoModalMaritimo': 'modal',
        'filTipoOperacaoModalTerrestre': 'modal',
        'situacaoAbertos': 'processes',
        'situacaoEmbarcados': 'processes',
        'filTypeCargoTeus': 'type_cargo',
        'filTypeCargoTons': 'type_cargo',
        'filPeriodoDtInicio': 'beginDate',
        'filPeriodoDtFim': 'endDate',
        'filPeriodoDtInicioInvoice': 'beginDateInvoice',
        'filPeriodoDtFimInvoice': 'endDateInvoice',
        'filOrigem': 'origin',
        'filDestino': 'destination',
        'filTipoEquipamento': 'equipment',
        'filAgent': 'agent'
      },
      noFilterList: ['filPeriodo']
    }
  },
  props: {
    activeFilterList: {
      type: Object,
      required: false
    }
  },
  components: {
    FilterItemApplied
  },
  computed: {
    filters () {
      return this.$store.getters.aplliedFilters || null
    },
    hasFilterApplied () {
      let me = this

      let hasFilter = Object.entries(this.filters).filter(function (item) {
        if (me.noFilterList.indexOf(item[0]) !== -1) {
          // Se filtro na lista de ignorados
          return false
        } else if (!me.activeFilters.hasOwnProperty(item[0])) {
          // Se filtro na lista de ativos para a página
          return false
        } else {
          if (item[1] === null) {
            // Se nulo
            return false
          } else if (item[1] === '') {
            // Se vazio
            return false
          } else if (item[1] === undefined) {
            // Se indefinido
            return false
          } else if (Array.isArray(item[1]) && item[1].length <= 0) {
            // Se array e nenhuma opção
            return false
          }

          return true
        }
      })

      return hasFilter.length > 0
    },
    activeFilters: function () {
      let me = this
      let showFilters = {}

      Object.keys(this.filters).forEach(function (key) {
        if (me.activeFilter(key)) {
          showFilters[key] = me.filters[key]
        }
      })

      return showFilters
    }
  },
  methods: {
    activeFilter (filterName) {
      let equivalent = this.dic[filterName]

      if (this.activeFilterList.hasOwnProperty(equivalent)) {
        return true
      }
      return false
    }
  }
}
</script>

<style scoped>
  .containerFilters {
  }
</style>
